import { QuestionAndAnswer } from 'lib/types';
import { ChildItem } from './stateItems';
import { FollowUpQA, StreamAnswerRequest, qnaAPI } from './qnaAPI';
import { trpc } from 'lib/trpc';

export interface AdditionalQAProps {
  queryId: string;
  followUpQAs: FollowUpQA[];
  deletedSources: string[];
  isStreaming: boolean;
  streamEndListeners: Array<() => void>;
  abort: (() => void) | null;
  retried: boolean;
}

export interface QuestionAnswerState
  extends QuestionAndAnswer,
    AdditionalQAProps {}

const retryRE = /^(I'm sorry|I'm unable)/;

export class QuestionAnswer {
  constructor(private state: ChildItem<QuestionAnswerState, QuestionAnswer>) {}

  /**
   * Get the question
   *
   * @param latest whether to use the latest or state value (false should be used when rendering)
   * @returns
   */
  public getQuestion(latest = false): string {
    return this.state.get('question', latest);
  }

  /**
   * Get the question and throw an error if it is not set
   *
   * @param latest whether to use the latest or state value (false should be used when rendering)
   * @returns
   */
  public safeGetQuestion(latest = false): string {
    const question = this.getQuestion(latest);
    if (!question) throw new Error('Question is not set');
    return question;
  }

  /**
   * Get the answer
   *
   * @param latest whether to use the latest or state value (false should be used when rendering)
   * @returns
   */
  public getAnswer(latest = false): string {
    return this.state.get('answer', latest);
  }

  /**
   * Whether there is an answer or not
   *
   * @param latest whether to use the latest or state value (false should be used when rendering)
   * @returns
   */
  public hasAnswer(latest = false): boolean {
    return this.state.get('answer', latest).length > 0;
  }

  /**
   * Whether there is an answer or not
   *
   * @param latest whether to use the latest or state value (false should be used when rendering)
   * @returns
   */
  public getCreatedAt(latest = false): string {
    return this.state.get('createdAt', latest);
  }

  /**
   * Sets the answer
   *
   * Cannot be used during rendering (modifies state)
   *
   * @returns
   */
  private setAnswer(answer: string) {
    this.state.set('answer', answer);
  }

  /**
   * Get the query id
   *
   * @param latest whether to use the latest or state value (false should be used when rendering)
   * @returns
   */
  public getQueryId(latest = false): string {
    return this.state.get('queryId', latest);
  }

  /**
   * Get the question id
   *
   * @param latest whether to use the latest or state value (false should be used when rendering)
   * @returns
   */
  public getQuestionId(latest: boolean): number {
    return this.state.get('id', latest);
  }

  /**
   * Get the follow up questions
   *
   * @param latest whether to use the latest or state value (false should be used when rendering)
   * @returns
   */
  private getFollowUps(latest = false): FollowUpQA[] {
    return this.state.get('followUpQAs', latest);
  }

  public setDeletedSources(deletedSources: string[]) {
    this.state.set('deletedSources', deletedSources);
  }

  /**
   * Get the user deleted sources
   *
   * @param latest whether to use the latest or state value (false should be used when rendering)
   * @returns
   */
  private getDeletedSources(latest = false): string[] {
    return this.state.get('deletedSources', latest);
  }

  /**
   * Sets the streaming state
   *
   * Cannot be used during rendering (modifies state)
   *
   * @returns
   */
  private setIsStreaming(isStreaming: boolean) {
    this.state.set('isStreaming', isStreaming);
  }

  /**
   * DB id of the question
   *
   * @param latest whether to use the latest or state value (false should be used when rendering)
   * @returns
   */
  private getId(latest = false): number {
    return this.state.get('id', latest);
  }

  /**
   * Get all stream end listeners
   *
   * @param latest whether to use the latest or state value (false should be used when rendering)
   * @returns
   */
  private getStreamEndListners(latest: boolean): Array<() => void> {
    return this.state.get('streamEndListeners', latest);
  }

  /**
   * Add a callback function to array of stream end listeners
   *
   * @param cb callback function to be added to list of functions to be executed after stream has finished
   */
  public addStreamEndListners(cb: () => void): void {
    const listeners = this.state.get('streamEndListeners', true);
    this.state.set('streamEndListeners', [...listeners, cb]);
  }

  /**
   * clear stream end listeners
   *
   */
  public clearStreamEndListners(): void {
    this.state.set('streamEndListeners', []);
  }

  /**
   * Sets the answer
   *
   * Cannot be used during rendering (modifies state)
   *
   * @returns
   */
  private appendAnswer(text: string): void {
    const oldAnswer = this.getAnswer(true);
    this.setAnswer(oldAnswer + text);
  }

  private processChunk(text: string): void {
    this.appendAnswer(text);
    const answer = this.getAnswer(true);
    const retried = this.state.get('retried', true);
    const shouldRetry = !retried && answer.length < 30 && retryRE.test(answer);
    if (shouldRetry) {
      this.state.set('retried', true);
      this.stopStreaming(true);
      this.regenerate();
    }
  }

  /**
   * Sets the answer
   *
   * Cannot be used during rendering (modifies state)
   *
   * @returns
   */
  private setAbort(value: (() => void) | null) {
    this.state.set('abort', value);
  }

  /**
   * Whether the question is actively streaming the answer or not
   *
   * @param latest whether to use the latest or state value (false should be used when rendering)
   * @returns
   */
  public getIsStreaming(latest = false): boolean {
    return this.state.get('isStreaming', latest);
  }

  /**
   * Updates the question in the database and in local state subsequently
   *
   * @param question new value for the question to be updated
   *
   */

  public async updateQuestion(question: string) {
    const id = this.getId();
    await trpc.chat.update.mutate({ id, question });
    this.state.set('question', question);
  }

  /**
   * Starts streaming the answer for the question
   *
   * Cannot be used during rendering (modifies state and makes API calls)
   *
   * @returns
   */
  public async stream() {
    this.setAnswer('');
    this.stopStreaming(true);
    this.setIsStreaming(true);

    const question = this.getQuestion(true);
    const query_id = this.getQueryId(true);
    const question_id = this.getId(true);
    const source_list = this.getDeletedSources(true);
    const followup_qas = this.getFollowUps(true);
    const data: StreamAnswerRequest = {
      question,
      question_id,
      query_id,
      source_list,
      followup_qas,
      with_upload: true,
    };
    const abort = await qnaAPI.stream(
      data,
      (chunk) => this.processChunk(chunk),
      async (content) => {
        this.setIsStreaming(false);
        const id = this.getId(true);
        await trpc.chat.update.mutate({ id, answer: content });
        const streamEndListeners = this.getStreamEndListners(true);
        streamEndListeners?.forEach((listener) => listener());
      },
    );
    this.setAbort(abort);
  }

  /**
   * Stop streaming the answer
   *
   * Cannot be used during rendering (modifies state and makes API calls)
   *
   * @returns
   */
  public stopStreaming(latest: boolean): void {
    const abortAnswer = this.state.get('abort', latest);
    if (abortAnswer) abortAnswer();
    this.setAbort(null);
    this.setIsStreaming(false);
    this.clearStreamEndListners();
  }

  /**
   * Regenerates the answer to the question
   *
   * Cannot be used during rendering (modifies state and makes API calls)
   *
   * @returns
   */
  public async regenerate() {
    await this.stream();
  }
}
