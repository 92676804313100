import { Search } from './search';
import { StateItem, useStateItem } from './stateItem';

export class SourceManagement {
  constructor(
    private search: Search,
    private isOpen: StateItem<boolean>,
  ) {}

  public getIsOpen(): boolean {
    return this.isOpen.get();
  }

  public open(): void {
    this.isOpen.set(true);
  }

  public close(): void {
    this.isOpen.set(false);
  }

  public regenerate(): void {
    const allQAs = this.search.getQuestionsAndAnswers(true);
    const lastQuestion = allQAs[allQAs.length - 1];
    lastQuestion.regenerate();
    this.close();
  }
}

export function useSourceManagement(search: Search): SourceManagement {
  return new SourceManagement(search, useStateItem(false));
}
