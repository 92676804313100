import { MutableRefObject, useRef } from "react";

export class RefItem<T> {

  constructor(private value: MutableRefObject<T>) {}

  public get(): T {
    return this.value.current;
  }

  public set(value: T): void {
    this.value.current = value;
  }
}

export function useRefItem<T>(initial: T): RefItem<T> {
  return new RefItem(useRef<T>(initial));
}