import { StateItem, useStateItem } from 'providers/stateItem';
import {
  qnaAPI,
  Source,
  StreamAnswerRequest,
  type StatisticsResponse,
} from './qnaAPI';
import { RefItem, useRefItem } from './refItem';
import { trpc } from 'lib/trpc';

export class AnonymousQuestionAnswer {
  constructor(
    private queryId: StateItem<string>,
    private anonymousId: StateItem<string>,
    private title: StateItem<string>,
    private sources: StateItem<Source[]>,
    private statistics: StateItem<StatisticsResponse[]>,
    private tags: StateItem<string[]>,
    private question: StateItem<string>,
    private answer: StateItem<string>,
    private createdAt: StateItem<string>,
    private isStreaming: StateItem<boolean>,
    private abort: RefItem<(() => void) | null>,
  ) {}

  /**
   * Get the query id
   *
   * @param latest whether to use the latest or state value (false should be used when rendering)
   * @returns
   */
  public getQueryId(latest: boolean): string {
    return this.queryId.get(latest);
  }

  public setQueryId(queryId: string): void {
    this.queryId.set(queryId);
  }

  public getAnonymousId(latest: boolean): string {
    return this.anonymousId.get(latest);
  }

  public setAnonymousId(anonymousId: string): void {
    this.anonymousId.set(anonymousId);
  }

  public getTitle(latest: boolean): string {
    return this.title.get(latest);
  }

  public setTitle(title: string): void {
    this.title.set(title);
  }

  public getSources(latest: boolean): Source[] {
    return this.sources.get(latest);
  }

  public setSources(sources: Source[]): void {
    this.sources.set(sources);
  }

  public getStatistics(latest: boolean): StatisticsResponse[] {
    return this.statistics.get(latest) as StatisticsResponse[];
  }

  public setStatistics(statistics: StatisticsResponse[]): void {
    this.statistics.set(statistics);
  }

  public getTags(latest: boolean): string[] {
    return this.tags.get(latest);
  }

  public setTags(tags: string[]): void {
    this.tags.set(tags);
  }

  /**
   * Get the question
   *
   * @param latest whether to use the latest or state value (false should be used when rendering)
   * @returns
   */
  public getQuestion(latest: boolean): string {
    return this.question.get(latest);
  }

  public setQuestion(text: string): void {
    this.question.set(text);
  }

  /**
   * Get the question and throw an error if it is not set
   *
   * @param latest whether to use the latest or state value (false should be used when rendering)
   * @returns
   */
  public safeGetQuestion(latest: boolean): string {
    const question = this.getQuestion(latest);
    if (!question) throw new Error('Question is not set');
    return question;
  }

  public getAnswer(latest: boolean): string {
    return this.answer.get(latest);
  }

  /**
   * Whether there is an answer or not
   *
   * @param latest whether to use the latest or state value (false should be used when rendering)
   * @returns
   */
  public hasAnswer(latest: boolean): boolean {
    return this.answer.get(latest).length > 0;
  }

  /**
   * Whether there is an answer or not
   *
   * @param latest whether to use the latest or state value (false should be used when rendering)
   * @returns
   */
  public getCreatedAt(latest: boolean): string {
    return this.createdAt.get(latest);
  }

  /**
   * Alias for getAnswer
   * @param latest
   * @returns
   */
  public getQuestAnswer(latest: boolean): string {
    return this.getAnswer(latest);
  }

  /**
   * Whether the question is actively streaming the answer or not
   *
   * @param latest whether to use the latest or state value (false should be used when rendering)
   * @returns
   */
  public getIsStreaming(latest: boolean): boolean {
    return this.isStreaming.get(latest);
  }

  /**
   * Sets the answer
   *
   * Cannot be used during rendering (modifies state)
   *
   * @returns
   */
  public setAnswer(text: string): void {
    this.answer.set(text);
  }

  /**
   * Sets the answer
   *
   * Cannot be used during rendering (modifies state)
   *
   * @returns
   */
  private appendAnswer(text: string): void {
    const oldAnswer = this.answer.get(true);
    this.answer.set(oldAnswer + text);
  }

  /**
   * Aborts the current streaming answer
   *
   * Cannot be used during rendering (modifies state)
   *
   * @returns
   */
  private setAbort(value: (() => void) | null) {
    this.abort.set(value);
  }

  /**
   * Starts streaming the answer for the question
   *
   * Cannot be used during rendering (modifies state and makes API calls)
   *
   * @returns
   */
  public async stream(deleteExisting = false): Promise<void> {
    const queryId = this.queryId.get(true);
    const anonymousId = this.anonymousId.get(true);
    const question = this.getQuestion(true);
    this.isStreaming.set(true);
    const data: StreamAnswerRequest = {
      followup_qas: [],
      question,
      question_id: 1,
      query_id: queryId,
      source_list: [],
      with_upload: true,
    };
    const abort = await qnaAPI.stream(
      data,
      (chunk) => this.appendAnswer(chunk),
      async (content) => {
        this.isStreaming.set(false);
        await trpc.chat.createUnauthQuestionAnswer.mutate({
          anonymousId,
          question,
          answer: content,
          queryId,
          sources: this.getSources(true),
          tags: this.getTags(true),
          title: this.getTitle(true),
          deleteExisting,
        });
      },
    );
    this.setAbort(abort);
  }

  /**
   * Alias function for stream.
   * @param deleteExisting
   * @returns
   */
  public async streamAnonymousAnswer(deleteExisting = false): Promise<void> {
    return this.stream(deleteExisting);
  }

  /**
   * Stop streaming the answer
   *
   * Cannot be used during rendering (modifies state and makes API calls)
   *
   * @returns
   */
  public stopStreaming(): void {
    const abortAnswer = this.abort.get();
    if (abortAnswer) abortAnswer();
    this.setAbort(null);
    this.isStreaming.set(false);
  }

  public clear() {
    this.setQueryId('');
    this.setAnonymousId('');
    this.setQuestion('');
    this.answer.set('');
    this.isStreaming.set(false);
    this.setAbort(null);
  }

  /**
   * Dummy function to replicate the interface of the questionAnswer klass
   */
  public async regenerate() {
    // no response needed
  }

  /**
   * Dummy function to replicate the interface of the questionAnswer klass
   */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public updateQuestion(q: string) {
    // no response needed
  }
}

export function useAnonymousQuestionAnswer(): AnonymousQuestionAnswer {
  return new AnonymousQuestionAnswer(
    useStateItem(''), //queryId
    useStateItem(''), //anonymousId
    useStateItem(''), //title
    useStateItem([]), //sources
    useStateItem([]), //statistics
    useStateItem([]), //tags
    useStateItem(''), //question
    useStateItem(''), //answer
    useStateItem(new Date().toString()), //createdAt
    useStateItem(false), //isStreaming
    useRefItem(null), //abort
  );
}
