import { analytics } from 'lib/analytics';
import { ChangeEvent, FormEvent } from 'react';
import { Search } from './search';
import { StateItem, useStateItem } from './stateItem';

export class FollowUpQuestionForm {
  constructor(
    private search: Search,
    private inputValue: StateItem<string>,
    private errorMessage: StateItem<string>,
  ) {}

  public getInputValue(): string {
    return this.inputValue.get();
  }

  public setInputValue(value: string) {
    this.inputValue.set(value);
  }

  public getErrorMessage(): string {
    return this.errorMessage.get(true);
  }

  public setErrorMessage(message: string) {
    this.errorMessage.set(message);
  }

  private clearErrorMessage() {
    Boolean(this.getErrorMessage()) && this.setErrorMessage('');
  }

  public onValueChange(e: ChangeEvent<HTMLInputElement>): void {
    this.clearErrorMessage();
    this.setInputValue(e.target.value);
  }

  public async onSubmit(e: FormEvent<HTMLFormElement>): Promise<void> {
    e.preventDefault();
    this.clearErrorMessage();

    const followUpQuestion = this.getInputValue();
    if (!Boolean(followUpQuestion)) {
      this.setErrorMessage('Empty input, please ask a question.');
      return;
    }
    try {
      analytics.generic(
        this.search.getUser(),
        'Question asked | Type: User Follow up',
        {
          followUpQuestion,
        },
      );

      await this.search.createQuestion(
        followUpQuestion,
        true, // isFollowUp
      );
      this.inputValue.set('');
    } catch (error) {
      this.setErrorMessage(
        error.message ?? ' Something went wrong. Please try again.',
      );
    }
  }

  public clear() {
    this.inputValue.set('');
    this.setErrorMessage('');
  }
}

export function useFollowUpQuestionForm(search: Search) {
  return new FollowUpQuestionForm(search, useStateItem(''), useStateItem(''));
}
