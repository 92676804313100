import {
  array,
  boolean,
  literal,
  nativeEnum,
  number,
  object,
  string,
  union,
} from 'zod';

export enum ReactionEnum {
  LIKE = 'LIKE',
  DISLIKE = 'DISLIKE',
  UNSET = 'UNSET',
}

export enum StripeCustomerPortalFlowDataType {
  PAYMENT_METHOD_UPDATE = 'payment_method_update',
  SUBSCRIPTION_CANCEL = 'subscription_cancel',
  SUBSCRIPTION_UPDATE = 'subscription_update',
  SUBSCRIPTION_UPDATE_CONFIRM = 'subscription_update_confirm',
}

export enum StripeCheckoutSessionMode {
  PAYMENT = 'payment',
  SETUP = 'setup',
  SUBSCRIPTION = 'subscription',
}

export enum UseCaseTypeEnum {
  RESEARCH = 'RESEARCH',
}
export const isNumber = number().int();
export const isString = string();
export const isBoolean = boolean();
export const isUUID = isString.uuid();
export const isDefaultString = isString.default('');
export const isOptionalString = isString.optional();
export const isOptionalNumber = isNumber.optional();
export const isOptionalBoolean = isBoolean.optional();

export const isUser = object({
  id: isNumber,
  authUserId: isUUID,
});

export const isUserCreate = object({
  authUserId: isUUID,
  currentDate: isOptionalString,
});

export const isUserUpdate = isUser.extend({
  isNewUser: isOptionalBoolean,
  firstName: isOptionalString,
  lastName: isOptionalString,
  phoneNumber: isOptionalString,
  companyName: isOptionalString,
  role: isOptionalString,
  team: isOptionalString,
  currentDate: isOptionalString,
  numQuestionsAskedToday: isOptionalNumber,
});

export const isCreateUserSubscription = isUser.extend({
  priceId: isOptionalString,
});

export const isUpdateUserSubscription = isUser.extend({
  subscriptionId: isString,
});
export const isCreateSubscriptionSessionCheckout = isUser.extend({
  queryId: isOptionalString,
});
export const isUserCustomerPortal = isUser.extend({
  flowDataType: nativeEnum(StripeCustomerPortalFlowDataType).optional(),
});

export const isUpdateUserProfession = isUser.extend({
  professionId: isNumber,
});

export const isCheckUserCanAskQuestion = isUser.extend({
  userDate: isString,
});

export const isQuestion = object({
  data: object({
    question: isString,
    s3_paths: array(isString),
    organization_id: isNumber.nullable(),
    industry_id: isNumber.nullable(),
  }),
});

export const isResearchData = object({
  query_id: isNumber,
  query: isString,
  search_id: isNumber,
});

export const isResearchQuestion = object({
  data: isResearchData.extend({
    question: isString,
  }),
});

export const isSuggestedQuestions = object({
  data: isResearchData,
});

export const isQuestionAnswer = object({
  question: isString,
  answer: isString,
  comment: isDefaultString,
  reaction: nativeEnum(ReactionEnum).default(ReactionEnum.UNSET),
  queryId: isUUID,
});

export const isBatchOrganizationsAndQuestions = object({
  data: object({
    organization_ids: array(isNumber),
    email: isString,
    questions: array(isString),
  }),
});

export const isOrganizationOnePager = object({
  data: object({
    organization_id: isNumber,
    email: isString,
  }),
});

export const isDownloadableQuestionAnswer = object({
  question: isDefaultString,
  answer: isDefaultString,
  comment: isDefaultString,
  reaction: nativeEnum(ReactionEnum).default(ReactionEnum.UNSET),
  searchId: isNumber,
  fileURL: isString,
  companyName: isString,
});

export const isQAndAUpdate = object({
  id: isNumber,
  question: isOptionalString,
  answer: isOptionalString,
  comment: isOptionalString,
  reaction: nativeEnum(ReactionEnum).optional(),
});

export const isLastNQuestions = object({
  numberOfQuestionsToGet: isNumber,
  authUserId: isUUID,
});

export const isSearchUpdate = object({
  id: isNumber,
  name: isDefaultString,
  isActive: isOptionalBoolean,
  organizationId: isOptionalNumber,
  industryId: isOptionalNumber,
});

export const isSearchId = object({ searchId: isNumber });

export const isDocumentsToProcess = object({
  data: object({
    s3_paths: array(isString),
    is_generate_title: isBoolean,
  }),
});

export const isDocumentsToProcessAndCreate = object({
  data: object({
    s3_paths: array(isString),
    is_generate_title: isBoolean,
    activeSearchId: isNumber,
    uploadedFiles: array(
      object({
        name: isString,
        size: isNumber,
      })
    ),
  }),
});

export const isFile = object({
  name: isString,
  size: isNumber,
  fileURL: isString,
  isSelected: isBoolean.default(true),
  searchId: isNumber,
});

export const isFileUpdate = object({
  id: isNumber,
  isSelected: isBoolean,
});

export const isInvite = object({
  authUserId: isUUID,
  recipientEmail: isString.email(),
});

export const isSearchWithUseCase = object({
  name: isDefaultString,
  useCaseType: nativeEnum(UseCaseTypeEnum),
});

export const isSearchWithUseCaseAndQuery = object({
  useCaseType: nativeEnum(UseCaseTypeEnum),
  queryTermText: isString.min(1),
});

export const isSearchWithUseCaseList = object({
  useCaseType: nativeEnum(UseCaseTypeEnum),
});

export const isSearchWithUseCaseUpdate = object({
  id: isNumber,
  name: isDefaultString.optional(),
  isActive: isOptionalBoolean,
  useCaseType: nativeEnum(UseCaseTypeEnum).optional(),
});

export const isSignUp = object({
  email: isString,
  password: isString,
});

export const isResendSignUp = object({
  email: isString,
});

export const isForgotPasswordSubmit = object({
  email: isString,
  code: isString,
  password: isString,
});

export const isConfirmSignup = object({
  email: isString,
  code: isString,
});

export const isGetUser = object({
  accessToken: isString,
});

export const isSSOLogin = object({
  code: isString,
});

export const isSearchQuestionOnly = object({
  question: isString,
});

export const isSearchSetAnswer = object({
  queryId: isString,
  answer: isString,
});

export const isSearchSetTags = object({
  queryId: isString,
  tags: array(isString),
});

export const isSearchSetTitle = object({
  queryId: isString,
  title: isString,
});

export const isSearchSetSources = object({
  queryId: isString,
  sources: array(object({ url: isString, title: isString })),
});

export const isStatisticsResponse = union([
  object({
    type: union([literal('bar'), literal('pie')]),
    graphTitle: isString,
    unit: isOptionalString,
    data: array(
      object({
        id: union([isNumber, isString]).optional(),
        label: isString,
        value: isNumber,
      })
    ),
  }),
  object({
    type: literal('line'),
    graphTitle: isString,
    unit: isOptionalString,
    data: array(
      object({
        id: union([isNumber, isString]).optional(),
        label: isString,
        data: array(
          object({
            x: union([isString, isNumber]),
            y: union([isString, isNumber]),
          })
        ),
      })
    ),
  }),
]);

export const isSearchSetStatistics = object({
  queryId: isString,
  statistics: isStatisticsResponse.array(),
});

export const isSearchSetDeletedSources = object({
  queryId: isString,
  deletedSources: array(object({ url: isString, title: isString })),
});

export const isAnonymousId = isUUID;

export const isAnonymousQuestionAnswer = object({
  anonymousId: isAnonymousId,
  queryId: isUUID,
  question: isString,
  answer: isString,
  sources: array(object({ url: isString, title: isString })),
  tags: array(isString),
  title: isString,
  deleteExisting: isOptionalBoolean,
});

export const isAnonymousSearch = object({
  anonymousId: isAnonymousId,
  queryId: isUUID,
});

export const isSummary = object({
  searchQueryId: isUUID,
  questionAnswerId: isNumber,
});

export const isOverview = object({
  summaryId: isUUID,
  content: isDefaultString,
});

export const isOverviewUpdate = object({
  id: isUUID,
  content: isString,
});

export const isKeyPoint = object({
  title: isString,
  value: isString,
  overviewId: isUUID,
});

export const isKeyPointsArray = array(isKeyPoint);

export const isKeyTopic = object({
  title: isString,
  content: isString,
  summaryId: isUUID,
});

export const isKeyTopicsArray = array(isKeyTopic);
