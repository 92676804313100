import useAuth from 'hooks/useAuth';
import { ServerTypes } from 'lib/types';
import { toast } from 'sonner';
import { Search } from './search';

interface ShareConfig {
  title: string;
  text: string;
  url: string;
}

export class Share {
  constructor(
    private user: Partial<ServerTypes.UserWithSubscriptionDetails> | null,
    private search: Search | undefined,
  ) {}

  private async perform(shareConfig: ShareConfig): Promise<void> {
    if (navigator && navigator.share !== undefined) {
      try {
        navigator.share(shareConfig);
      } catch (error) {
        if (error.toString().includes('AbortError')) {
          return;
        } else {
          toast.error(
            "Sorry, we couldn't share the content at this moment. Please try again.",
          );
        }
      }
    } else {
      try {
        await navigator.clipboard.writeText(shareConfig.url);
        toast.success('Share link successfully copied to clipboard.');
      } catch (error) {
        toast.error(
          "Sorry, we couldn't share the content at this moment. Please try again.",
        );
      }
    }
  }

  public async shareSearch(): Promise<void> {
    let shareConfig: ShareConfig | undefined;

    if (!this.search) {
      throw new Error('No search to share');
    }

    if (this.user) {
      const sharerName =
        this.user?.firstName && this.user.lastName
          ? `${this.user.firstName} ${this.user.lastName}`
          : `${this.user?.userName}`;
      shareConfig = {
        title: 'CleeAI Shared Resource',
        text: `${sharerName} shared with you the following CleeAI chat...`,
        url: `${location.href}?share=${this.user.userName}`,
      };
    } else {
      shareConfig = {
        title: 'CleeAI Shared Resource',
        text: `Hey 👋, here's the CleeAI chat shared with you ...`,
        url: `${location.href}&share=anonymous`,
      };
    }

    await this.perform(shareConfig);
  }

  public async shareInvite(): Promise<void> {
    if (!this.user) {
      throw new Error('No user');
    }
    const inviterName =
      this.user?.firstName && this.user.lastName
        ? `${this.user.firstName} ${this.user.lastName}`
        : `${this.user?.userName}`;

    const shareConfig = {
      title: 'Try CleeAI, a leading AI search engine',
      text: `${inviterName} wants you to try CleeAI`,
      url: this.generateInviteLink(),
    };

    await this.perform(shareConfig);
  }

  public generateInviteLink(): string {
    return `${location.origin}/create-account?share=${this.user?.userName?.split('@')[0] ?? ''}`;
  }
}

export function useShare(search?: Search): Share {
  const [user] = useAuth();
  return new Share(user, search);
}
