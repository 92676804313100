import { StateItem, useStateItem } from './stateItem';
import { RefItem, useRefItem } from './refItem';
import _ from 'lodash';
import {
  KeyTopicsRequest,
  qnaAPI,
  RequestData,
  StreamAnswerRequest,
} from './qnaAPI';
import { trpc } from 'lib/trpc';
import { KeyPoint } from 'components/KeyPoint/KeyPoint';
import { KeyTopic } from 'components/KeyTopics/KeyTopics';
import { ServerTypes } from 'lib/types';

type RequestDataType = 'overview' | 'keyPoints' | 'keyTopics';

export class Summary {
  constructor(
    private summaryId: StateItem<string>,
    private overviewId: StateItem<string>,
    private overviewText: StateItem<string>,
    private keyPoints: StateItem<KeyPoint[]>,
    private keyTopics: StateItem<KeyTopic[]>,
    private isCreatingSummary: StateItem<boolean>,
    private isOverviewStreaming: StateItem<boolean>,
    private isFetchingKeyPoints: StateItem<boolean>,
    private isFetchingKeyTopics: StateItem<boolean>,
    private isOverviewActive: StateItem<boolean>,
    private isKeyTopicsActive: StateItem<boolean>,
    private overviewAbort: RefItem<(() => void) | null>,
    private keyPointAbort: RefItem<AbortController | null>,
    private keyTopicAbort: RefItem<AbortController | null>,
  ) {}

  /**
   * Retrieve the summary id
   *
   * @param latest whether to use the ref values (latest) or state value (always false whe used in rendering components)
   * @returns
   */
  private getSummaryId(latest: boolean): string {
    return this.summaryId.get(latest);
  }

  /**
   * Set the summary id
   *
   * @param id - the id of the current summary
   */
  public setSummaryId(id: string): void {
    this.summaryId.set(id);
  }

  /**
   * get the overview id
   *
   * @param latest - whether to use the ref values (latest) or state value (always false whe used in rendering components)
   * @returns
   *
   */
  public getOverviewId(latest: boolean): string {
    return this.overviewId.get(latest);
  }

  /**
   * Set the overview id
   *
   * @param id - the id of the overview
   *
   */
  public setOverviewId(id: string): void {
    this.overviewId.set(id);
  }

  /**
   * get value of isCreatingSummary
   *
   * @param latest - whether to use the ref values (latest) or state value (always false whe used in rendering components)
   * @returns
   *
   */
  public getIsCreatingSummary(latest: boolean): boolean {
    return this.isCreatingSummary.get(latest);
  }

  /**
   * Set the isCreatingSummary
   *
   * @param value - true or false
   *
   */
  private setIsCreatingSummary(value: boolean): void {
    this.isCreatingSummary.set(value);
  }

  /**
   * get isOverviewStreaming
   *
   * @param latest - whether to use the ref values (latest) or state value (always false whe used in rendering components)
   * @returns
   *
   */
  public getIsOverviewStreaming(latest: boolean): boolean {
    return this.isOverviewStreaming.get(latest);
  }

  /**
   * Set the isOverviewStreaming
   *
   * @param value - true or false
   *
   */
  public setIsOverviewStreaming(value: boolean): void {
    this.isOverviewStreaming.set(value);
  }

  /**
   * get isFetchingKeyPoints
   *
   * @param latest - whether to use the ref values (latest) or state value (always false whe used in rendering components)
   * @returns
   *
   */
  public getIsFetchingKeyPoints(latest: boolean): boolean {
    return this.isFetchingKeyPoints.get(latest);
  }

  /**
   * Set the isFetchingKeyPoints
   *
   * @param value - true or false
   *
   */
  public setIsFetchingKeyPoints(value: boolean): void {
    this.isFetchingKeyPoints.set(value);
  }

  /**
   * get isFetchingKeyTopics
   *
   * @param latest - whether to use the ref values (latest) or state value (always false whe used in rendering components)
   * @returns
   *
   */
  public getIsFetchingKeyTopics(latest: boolean): boolean {
    return this.isFetchingKeyTopics.get(latest);
  }

  /**
   * Set the isFetchingKeyTopics
   *
   * @param value - true or false
   *
   */
  public setIsFetchingKeyTopics(value: boolean): void {
    this.isFetchingKeyTopics.set(value);
  }

  /**
   * Retrieve the overview text
   *
   * @param latest whether to use the ref values (latest) or state value (always false whe used in rendering components)
   * @returns
   */

  public getOverviewText(latest: boolean): string {
    return this.overviewText.get(latest);
  }

  /**
   * Set the overview text value
   *
   * @param overviewText - current overview text value
   */
  private setOverviewText(overviewText: string): void {
    this.overviewText.set(overviewText);
  }

  /**
   * Get the key points
   *
   * @param latest whether to use the ref values (latest) or state value (always false whe used in rendering components)
   * @returns
   */

  public getKeyPoints(latest: boolean): KeyPoint[] {
    return this.keyPoints.get(latest);
  }

  /**
   * Set the key points
   *
   * @param keyPoints - array of key points
   */
  public setKeyPoints(keyPoints: KeyPoint[]): void {
    this.keyPoints.set(keyPoints);
  }

  /**
   * Get the key topics
   *
   * @param latest whether to use the ref values (latest) or state value (always false whe used in rendering components)
   * @returns
   */

  public getKeyTopics(latest: boolean): KeyTopic[] {
    return this.keyTopics.get(latest);
  }

  /**
   * Set the key topics
   *
   * @param keyTopics - array of key topics
   */
  public setKeyTopics(keyTopics: KeyTopic[]): void {
    this.keyTopics.set(keyTopics);
  }

  /**
   * Get isOverviewActive
   *
   * @param latest whether to use the ref values (latest) or state value (always false whe used in rendering components)
   * @returns
   */

  public getIsOverviewActive(latest: boolean): boolean {
    return this.isOverviewActive.get(latest);
  }

  /**
   * Set isOverviewActive
   *
   * @param value boolean
   */

  public setIsOverviewActive(value: boolean): void {
    this.isOverviewActive.set(value);
  }

  /**
   * Get isKeyTopicsActive
   *
   * @param latest whether to use the ref values (latest) or state value (always false whe used in rendering components)
   * @returns
   */

  public getIsKeyTopicsActive(latest: boolean): boolean {
    return this.isKeyTopicsActive.get(latest);
  }

  /**
   * Set isKeyTopicsActive
   *
   * @param value boolean
   */

  public setIsKeyTopicsActive(value: boolean): void {
    this.isKeyTopicsActive.set(value);
  }

  /**
   * disable summary when search is changed or created
   *
   */

  public disableSummary(): void {
    this.isOverviewActive.set(false);
    this.isKeyTopicsActive.set(false);
  }

  /**
   * check before fetching/setting key topics if user has enabled key topics and is not already fetching them;
   *@returns
   */
  public shouldSetKeyTopics(): boolean {
    const isKeyTopicsActive = this.getIsKeyTopicsActive(true);
    const hasKeyTopics = Boolean(this.getKeyTopics(true).length);
    const isFetchingKeyTopics = this.getIsFetchingKeyTopics(true);
    return isKeyTopicsActive && !hasKeyTopics && !isFetchingKeyTopics;
  }

  /**
   * check before setting/streaming overview text
   * if it should be set/streamed
   *@returns
   */
  public shouldSetOverview(): boolean {
    const isOverviewActive = this.getIsOverviewActive(true);
    const isOverviewStreaming = this.getIsOverviewStreaming(true);
    //not checking if overview has text as it will have text while streaming;
    return isOverviewActive && !isOverviewStreaming;
  }

  /**
   * check before setting/fetching key points
   * if they should be fetched
   *@returns
   */
  public shouldSetKeyPoints(): boolean {
    const hasKeyPoints = Boolean(this.getKeyPoints(true).length);
    const isFetchingKeyPoints = this.getIsFetchingKeyPoints(true);
    return !hasKeyPoints && !isFetchingKeyPoints;
  }

  /**
   * initiates the loading state variables once a new question has been asked
   *
   */
  public initiateLoadingSkeletons(): void {
    const isOverviewActive = this.getIsOverviewActive(true);
    const isKeyTopicsActive = this.getIsKeyTopicsActive(true);
    isKeyTopicsActive && this.setIsFetchingKeyTopics(true);
    if (isOverviewActive) {
      this.setIsOverviewStreaming(true);
      this.setIsFetchingKeyPoints(true);
    }
  }

  /**
   * Set all summary values
   *
   * @param summary - existing summary of the last question
   */
  public async setSummaryValues(
    summary: ServerTypes.SummaryWithOverviewAndKeyTopics,
    data: RequestData,
  ) {
    const { overview, id: summaryId, keyTopics } = summary;
    this.setSummaryId(summaryId);

    const shouldSetKeyTopics = this.shouldSetKeyTopics();
    const shouldSetOverview = this.shouldSetOverview();
    const shouldSetKeyPoints = this.shouldSetKeyPoints();

    if (shouldSetKeyTopics) {
      if (keyTopics.length) {
        const noDuplicateVals = _.uniqBy(keyTopics, 'title');
        this.setKeyTopics(
          noDuplicateVals.map((keyTopic) => {
            const { title, content } = keyTopic;
            return { title, description: content };
          }),
        );
      } else {
        const keyTopicsData = this.generateReqDataObj(data, 'keyTopics');
        this.fetchKeyTopics(keyTopicsData);
      }
    }

    if (shouldSetOverview) {
      if (overview) {
        const { id: overviewId, content, keyPoints } = overview;
        this.setOverviewId(overviewId);
        this.setOverviewText(content);
        if (!Boolean(content)) {
          const overviewdata = this.generateReqDataObj(data, 'overview');
          this.streamOverview(overviewdata);
        }
        if (shouldSetKeyPoints) {
          if (keyPoints.length) {
            const noDuplicateVals = _.uniqBy(keyPoints, 'title');
            this.setKeyPoints(
              noDuplicateVals.map((keyPoint) => {
                const { title, value } = keyPoint;
                return {
                  title,
                  value,
                };
              }),
            );
          } else {
            const keyPointsReqData = this.generateReqDataObj(data, 'keyPoints');
            this.fetchKeyPoints(keyPointsReqData as StreamAnswerRequest);
          }
        }
      } else {
        await this.createOverview();
        const overviewAndKeyPointsReqData = this.generateReqDataObj(
          data,
          'overview',
        );
        this.streamOverview(overviewAndKeyPointsReqData as StreamAnswerRequest);
        this.fetchKeyPoints(overviewAndKeyPointsReqData as StreamAnswerRequest);
      }
    }
  }

  /**
   * Create new summary
   *
   * @param data - data of type StreamAnswerReques for calling qnaAPI endpoints
   */
  public async create(data: RequestData) {
    this.clear();
    const { query_id, question_id } = data;

    const isOverviewActive = this.getIsOverviewActive(true);
    const isKeyTopicsActive = this.getIsKeyTopicsActive(true);

    if (!isOverviewActive && !isKeyTopicsActive) return;
    try {
      this.setIsCreatingSummary(true);
      const newSummary = await trpc.summary.create.mutate({
        searchQueryId: query_id,
        questionAnswerId: question_id,
      });
      const summaryId = newSummary.id;
      this.setSummaryId(summaryId);
      this.setIsCreatingSummary(false);

      const overviewAndKeyPointsData = this.generateReqDataObj(
        data,
        'overview',
      );
      const keyTopicsData = this.generateReqDataObj(data, 'keyTopics');

      isKeyTopicsActive && this.fetchKeyTopics(keyTopicsData);
      if (isOverviewActive) {
        await this.createOverview();
        this.streamOverview(overviewAndKeyPointsData as StreamAnswerRequest);
        this.fetchKeyPoints(overviewAndKeyPointsData as StreamAnswerRequest);
      }
    } catch (error) {
      console.error('Summary error: ', error);
    } finally {
      this.getIsCreatingSummary(true) && this.setIsCreatingSummary(false);
    }
  }

  /**
   * Generate the request data for overview and key topics (for key points data type already fits request)
   */
  private generateReqDataObj(data: RequestData, reqDataType: RequestDataType) {
    const { query_id, source_list, followup_qas, question, question_id } = data;
    let requestData;
    switch (reqDataType) {
      case 'keyTopics':
        requestData = {
          question,
          query_id,
          question_id,
          source_list,
          followup_qas,
          with_upload: false,
        };
        break;
      case 'overview':
      case 'keyPoints':
        requestData = {
          ...data,
          with_upload: false,
        };
        break;
    }
    return requestData ?? data;
  }

  /**
   * Create new overview
   */
  private async createOverview() {
    const summaryId = this.getSummaryId(true);
    const newOverview = await trpc.summary.createOverview.mutate({
      summaryId,
    });
    this.setOverviewId(newOverview.id);
  }

  /**
   * Append to the existing overview value as it is being streamed
   *
   * @param text - new chunk of text being streamed
   */
  private appendOverviewText(text: string): void {
    const oldOverview = this.getOverviewText(true);
    this.setOverviewText(oldOverview + text);
  }

  /**
   * Callback function for handling the finished overview stream
   *
   * @param text - the finished text returnded by the stream
   */
  private async handleOverviewStreamFinished(text: string): Promise<void> {
    this.isOverviewStreaming.set(false);
    const overviewId = this.overviewId.get(true);
    if (!overviewId) throw new Error('No overview id available');
    await trpc.summary.updateOverview.mutate({
      id: overviewId,
      content: text,
    });
  }

  /**
   * Stops any data fetching for the summary
   * - used when a new/other search is rendered
   */
  public stopFetchingAndClear() {
    this.stopStreamingOverview();
    this.keyPointAbort.get()?.abort();
    this.keyTopicAbort.get()?.abort();
    this.clear();
  }

  /**
   * Starts streaming the overview
   *
   * Cannot be used during rendering (modifies state and makes API calls) TODO: IS THIS CORRECT
   *
   */
  public async streamOverview(data: StreamAnswerRequest) {
    this.setOverviewText('');
    this.stopStreamingOverview();
    this.isOverviewStreaming.set(true);

    const abort = await qnaAPI.stream(
      data,
      (chunk) => this.appendOverviewText(chunk),
      async (content) => await this.handleOverviewStreamFinished(content),
      'open_research_overview',
    );
    this.overviewAbort.set(abort);
  }

  /**
   * Stops the overview streaming
   */
  public stopStreamingOverview(): void {
    const abortOverview = this.overviewAbort.get();
    abortOverview && abortOverview();
    this.overviewAbort.set(null);
    this.setIsOverviewStreaming(false);
  }

  /**
   * Fetch key points, add to summary and save to db
   *
   * @param data - request data for fetching key points (of type RequestData)
   */
  private async fetchKeyPoints(data: StreamAnswerRequest): Promise<void> {
    const overviewId = this.getOverviewId(true);
    this.setIsFetchingKeyPoints(true);
    try {
      const { result, abortController } = await qnaAPI.fetchKeyPoints(data);
      const noDuplicateVals = _.uniqBy(result, 'title');
      this.setKeyPoints(noDuplicateVals);
      this.keyPointAbort.set(abortController);
      const keyPointsWithOverviewId = result.map((keyPoint) => ({
        ...keyPoint,
        overviewId,
      }));
      await trpc.summary.createKeyPoints.mutate(keyPointsWithOverviewId);
    } catch (error) {
      console.error('Error fetching key points: ', error);
    } finally {
      this.setIsFetchingKeyPoints(false);
    }
  }

  /**
   * Fetch key topics, add to summary and save to db
   *
   * @param data - request data for fetching key topics (of type KeyTopicsRequest)
   */
  private async fetchKeyTopics(data: KeyTopicsRequest) {
    const summaryId = this.getSummaryId(true);
    this.setIsFetchingKeyTopics(true);
    try {
      const { result, abortController } = await qnaAPI.fetchKeyTopics(data);
      const noDuplicateVals = _.uniqBy(result, 'title');
      this.setKeyTopics(noDuplicateVals);
      this.keyTopicAbort.set(abortController);
      const keyTopicsWithOverviewId = result.map((keyTopic) => {
        const { title, description } = keyTopic;
        return {
          title,
          content: description,
          summaryId,
        };
      });
      await trpc.summary.createKeyTopics.mutate(keyTopicsWithOverviewId);
    } catch (error) {
      console.error('Error fetching key topics: ', error);
    } finally {
      this.setIsFetchingKeyTopics(false);
    }
  }

  public clear(): void {
    this.setSummaryId('');
    this.setOverviewId('');
    this.setOverviewText('');
    this.keyPoints.set([]);
    this.keyTopics.set([]);
    this.setIsOverviewStreaming(false);
    this.setIsFetchingKeyPoints(false);
    this.setIsFetchingKeyTopics(false);
    this.overviewAbort.set(null);
    this.keyPointAbort.set(null);
    this.keyTopicAbort.set(null);
  }
}

export function useSummary(): Summary {
  return new Summary(
    useStateItem(''), // summaryId
    useStateItem(''), // overviewId
    useStateItem(''), // overviewText
    useStateItem([]), // keyPoints
    useStateItem([]), // keyTopics
    useStateItem(false), // isCreatingSummary
    useStateItem(false), // isOverviewStreaming
    useStateItem(false), // isFetchingKeyPoints
    useStateItem(false), // isOverviewStreaming
    useStateItem(false), // isOverviewActive
    useStateItem(false), // isKeyTopicsActive
    useRefItem(null), //overviewAbort
    useRefItem(null), //keyPointAbort
    useRefItem(null), //keyTopicAbort
  );
}
