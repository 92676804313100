import { StateItem, useStateItem } from './stateItem';

/**
 * Represents one item of type T from the state array wrapped in
 * a object of type C to provide methods for interacting with and
 * updating the state.
 * 
 * This is used in the class of type C to update and retrieve the state
 * of the item. This in turn updates the parent.
 * 
 */
export class ChildItem<T, C> {
  constructor(
    private parent: StateItems<T, C>,
    private index: number,
  ) {}

  /**
   * Set a value in the state item.
   *  
   * @param key 
   * @param value 
   */
  public set<K extends keyof T>(key: K, value: T[K]): void {
    this.parent.setValueAt(this.index, key, value);
  }

  /**
   * Retrieve the state version of the value.
   *  
   * @param key 
   * @param latest whether to use the latest or state value (false should be used when rendering)
   * @returns 
   * 
   */
  public get<K extends keyof T>(key: K, latest = false): T[K] {
    return this.parent.getValueAt(this.index, key, latest);
  }
}

/**
 * Encapsulates the contructor for the class of type C.
 */
export interface Constructor<T, C> {
  new (state: ChildItem<T, C>): C;
}

/**
 * Encapsulates an array of state allowing each of the child items
 * to maintain state, but ultimately changing the state at the top level.
 * Whenever an item is retrieved it is wrapped in object of type C.
 * 
 */
export class StateItems<T, C> {

  constructor(
    private value: StateItem<T[]>,
    private klass: Constructor<T, C>,
  ) {
  }

  /**
   * Retrieve the raw values
   * 
   * @param latest whether to use the latest or state value (false should be used when rendering)
   * @returns 
   */
  private getValues(latest = false): T[] {
    return this.value.get(latest);
  }

  /**
   * Apply callback for each wrapped object
   * 
   * @param cb
   * @param latest whether to use the latest or state value (false should be used when rendering)
   */
  public forEach(cb: (obj: C, index: number, objs: C[]) => void, latest = false): void {
    this.getAll(latest).forEach(cb);
  }

  /**
   * Map each item from wrapped object to new type
   * 
   * @param cb
   * @param latest whether to use the latest or state value (false should be used when rendering loop)
   */
  public map<U>(cb: (obj: C, index: number, objs: C[]) => U, latest = false): U[] {
    return this.getAll(latest).map(cb);
  }

  /**
   * Map each item from wrapped object to new type
   * 
   * @param cb
   * @param latest whether to use the latest or state value (false should be used when rendering)
   */
  public some(cb: (obj: C, index: number, objs: C[]) => boolean, latest = false): boolean {
    return this.getAll(latest).some(cb);
  }

  /**
   * Get all the wrapped items
   * 
   * @param latest whether to use the latest or state value (false should be used when rendering)
   */
  public getAll(latest = false): C[] {
    return this.getValues(latest).map((_, i) => this.getAt(i));
  }

  /**
   * Get a wrapped object (no need to use latest here - it is used on the child)
   * 
   * @param latest whether to use the latest or state value (false should be used when rendering)
   */
  public getAt(index: number): C {
    const childItem = new ChildItem(this, index);
    return new this.klass(childItem);
  }

  /**
   * Retreive a value from the state 
   * 
   * @param index
   * @param key 
   * @param latest whether to use the latest or state value (false should be used when rendering)
   * @returns 
   */
  public getValueAt<K extends keyof T>(index: number, key: K, latest = false): T[K] {
    const values = this.getValues(latest);
    return values[index][key];
  }

  /**
   * Set a value on the state
   * 
   * @param index 
   * @param key 
   * @param value 
   */
  public setValueAt<K extends keyof T>(
    index: number,
    key: K,
    value: T[K],
  ): void {
    const values = this.getValues(true);
    const child = values[index];
    const updatedChild: T = { ...child, [key]: value };
    const newValue: T[] = [
      ...values.slice(0, index),
      updatedChild,
      ...values.slice(index + 1),
    ];
    this.value.set(newValue);
  }

  /**
   * Append one or more values to the state
   * 
   * @param value 
   */
  public append(...value: T[]): void {
    const values = this.getValues(true);
    const newValue: T[] = values.slice(0);
    newValue.push(...value);
    this.value.set(newValue);
  }

  /**
   * Retrieve the number of items 
   * 
   * @param latest whether to use the latest or state value (false should be used when rendering)
   * @returns 
   */
  public size(latest = false): number {
    const values = this.getValues(latest);
    return values.length;
  }

  /**
   * Clear the objects from the underlying state
   */
  public clear(): void {
    this.value.set([]);
  }
}

export function useStateItems<T, C>(
  klass: Constructor<T, C>,
): StateItems<T, C> {
  return new StateItems(useStateItem<T[]>([]), klass);
}
