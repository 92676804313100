import Cookies from 'js-cookie';
import { trpc } from 'lib/trpc';
import { useEffect } from 'react';

// Constants
const REFRESH_BUFFER = 5 * 60; // 5 minutes in seconds

// Helper function to decode JWT and extract expiration time
const decodeToken = (token: string): { exp: number } | null => {
  try {
    const payload = JSON.parse(atob(token.split('.')[1]));
    return { exp: payload.exp };
  } catch {
    return null;
  }
};

// Check if token is expired or about to expire
const isTokenExpired = (token?: string): boolean => {
  if (!token) return true;
  const decoded = decodeToken(token);
  return !decoded || Date.now() >= decoded.exp * 1000 - REFRESH_BUFFER * 1000;
};

// Function to refresh the access token
const refreshAccessToken = async (
  refreshToken: string,
): Promise<string | undefined> => {
  return await trpc.auth.refreshToken
    .mutate({ refreshToken })
    .then((data) => {
      return data.accessToken || undefined;
    })
    .catch((error) => {
      console.error('Token refresh failed:', error);
      return undefined;
    });
};

// Simplified Hook to Auto-Refresh Token
export function useRefreshAuthToken() {
  const refreshToken = Cookies.get('refreshToken');
  let accessToken = Cookies.get('accessToken');

  useEffect(() => {
    if (!refreshToken) return;

    const refresh = async () => {
      if (isTokenExpired(accessToken)) {
        const newToken = await refreshAccessToken(refreshToken);
        if (newToken) accessToken = newToken;
      }
    };

    // Run the refresh logic initially
    refresh();

    // Set up an interval to periodically check and refresh the token if needed
    const intervalId = setInterval(refresh, REFRESH_BUFFER * 1000); // Check every 5 minutes

    // Clean up interval on component unmount
    return () => clearInterval(intervalId);
  }, [accessToken, refreshToken]);

  return accessToken;
}
