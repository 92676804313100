import {
  useState,
  useRef,
  SetStateAction,
  Dispatch,
  MutableRefObject,
} from 'react';

/**
 * Allows state to be used as a property of an object encapsulating
 * application state.
 * 
 * It encapsulates the value as a pair of state and ref which it keeps in sync.
 * 
 * The ref value is used in business logic where the most recent value is required.
 * 
 * The state is used in the rendering loop where the pure value is required.
 * 
 */
export class StateItem<T> {
  private value: T;
  private ref: MutableRefObject<T>;
  public readonly setter: Dispatch<SetStateAction<T>>;

  constructor(state: [T, Dispatch<SetStateAction<T>>], ref: MutableRefObject<T>) {
    [this.value, this.setter] = state;
    this.ref = ref;
  }

  /**
   * Retrieves the value.
   * 
   * @param latest whether to use the latest or state value (false should be used when rendering)
   * @returns the value for rendering
   */
  public get(latest = false): T {
    return latest? this.ref.current : this.value;
  }

  /**
   * Updates the value
   * 
   * @param value the updated value
   */
  public set(value: T) {
    this.setter(value);
    this.ref.current = value;
  }
}

/**
 * Create a state item.
 * 
 * @param initial 
 * @returns 
 */
export function useStateItem<T>(initial: T): StateItem<T> {
  return new StateItem(useState<T>(initial), useRef<T>(initial));
}
