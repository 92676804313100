export const isDataIrrelevant = (
  data: number[],
  varianceThreshold = 0.01,
): boolean => {
  const mean = data.reduce((a, b) => a + b, 0) / data.length;
  const variance =
    data.reduce((sum, value) => sum + Math.pow(value - mean, 2), 0) /
    data.length;

  return variance < varianceThreshold;
};
