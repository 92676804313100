import { StateItem, useStateItem } from 'providers/stateItem';
import { RefItem, useRefItem } from 'providers/refItem';

export class Timer {
  constructor(
    private interval: number,
    private tick: StateItem<number>,
    private id: RefItem<NodeJS.Timer | null>,
  ) {}

  public getTicks(latest = false): number {
    return this.tick.get(latest);
  }

  public start() {
    const id = setInterval(() => {
      const oldTick = this.tick.get(true);
      this.tick.set(oldTick + 1);
    }, this.interval)
    this.id.set(id);
  }

  public reset() {
    const id = this.id.get();
    if (id) clearInterval(id);
    this.tick.set(0);
    this.id.set(null);
  }
}

export function useTimer(interval: number) {
  return new Timer(
    interval,
    useStateItem(0),
    useRefItem(null),
  )
}
