import { StateItem, useStateItem } from './stateItem';

interface FeatureFlag {
  enabled: boolean;
}

export class FeatureFlags {
  constructor(private featureFlags: StateItem<Record<string, FeatureFlag>>) {}

  public getFeatureFlags(): Record<string, FeatureFlag> {
    return this.featureFlags.get();
  }

  public getFeatureFlag(key: string): FeatureFlag | undefined {
    return this.featureFlags.get()[key];
  }

  async fetch() {
    try {
      const response = await fetch(
        `${process.env.NEXT_PUBLIC_SERVER_BASE_URL}/utils/fetch-feature-flags`,
      );

      if (!response.ok) {
        return;
      }

      const response_json = await response.json();
      this.featureFlags.set(response_json?.data?.featureFlags);
    } catch (err) {
      console.error('Error fetching feature flags', err);
      return;
    }
  }
}

export function useFeatureFlags(): FeatureFlags {
  return new FeatureFlags(useStateItem({}));
}
